import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/pro-regular-svg-icons'
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import mediaQuery from '../styles/mediaQuery'
import StyledTop from './styles/StyledTop'
import StyledTopMain from './styles/StyledTopMain'
import StyledLogo from './styles/StyledLogo'
import StyledLink, { LinkContainerExternal } from './styles/StyledLink'
import StyledBottom from './styles/StyledBottom'
import StyledBottomMain from './styles/StyledBottomMain'
import StyledText from './styles/StyledText'

export type Props = {
  logo?: string
  navTitle?: string
  navitems?: Array<{
    title?: string
    type?: 'email' | 'phoneNumber' | 'link' | string
    url: string
    icon?: string
  }>
  subnavitems?: Array<{
    title?: string
    url: string
  }>
  colophon?: string
  backlink?: {
    text: Array<string>
    url: string
  }
}

const ContainerContactFull = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // width: 44.25rem;
  // margin-top: 1.0625rem;
  max-width: 44.25rem;
  width: 100%;
  @media screen and (max-width: 1040px) {
    display: none;
  }
`
const ContainerContactLess = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.25rem;
  max-width: 44.25rem;
  width: 100%;
  @media screen and (min-width: 1041px) {
    display: none;
  };
  @media screen and (max-width: 870px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    max-width: initial;
    margin-top: 0;
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.5625rem;
  color: #8197AA;
  @media screen and (max-width: 870px) {
    font-size: 1.3125rem;
  }
`
const Icon = styled(FontAwesomeIcon)`
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
  color: #00AFD9;
  margin-right: 1.875rem;
  display: none;
  @media screen and (max-width: 870px) {
    display: block;
  }
`
const ContainerSocial = styled.div`
  cursor: pointer;
  width: 6.25rem;
  height: 1.5625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-top: 0.875rem;
  // margin-right: 5.75rem;
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 870px) {
    // margin-right: 2.25rem;
  }
`
const ContainerContact = styled.a`
  @media screen and (max-width: 870px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6875rem 0 1.6875rem 0;
    width: 100%;
    border-width:0;
    border-top-width: 1px;
    border-color: #163F62;
    border-style: solid;
  };
  cursor: pointer;
  text-decoration: none;
  &:hover ${StyledLink} {
    cursor: pointer;
    color: #00AFD9;
  }
`
const Styleda = styled.a`
  cursor: pointer;
  &:hover ${StyledIcon} {
    color: #00AFD9;
  }
`
const HoverLink = styled.span`
  color: #00AFD9;
  font-size: 0.8125rem;
  @media screen and (max-width: 870px) {
    font-size: 0.75rem;
  }
`

const StyledTextAu = styled.div`
  display: block;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  letter-spacing: 0px;
  color: #00AFD9;
  opacity: 1;
  &:hover ${HoverLink} {
    color: #E2F1F8;
  };
  @media screen and (max-width: 870px) {
    font-size: 0.75rem;
  }
`

const StyledTextA = styled.a`
  // margin-top: 0.625rem;
  line-height: 0.9375rem;
  text-decoration: unset;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin-top: 0.625rem;
    margin-bottom: 1.0625rem;
  }
  // ${mediaQuery('md')`
  //   margin-top: 0.625rem;
  // `}
`

export const Footer: React.FC<Props> = ({
  logo, navitems = [], colophon, backlink
}) => {
  return (
    <div 
      style={{ backgroundColor: '#012F57' }}
    >
      <StyledTop>
        <StyledTopMain>
          <StyledLogo
            src={logo}
            alt={'logo'}
            height={'8.5rem'}
            width={'3.25rem'}
          />
          <ContainerContactFull>
            <ContainerContact href="mailto:general@sda.org.au">
              <StyledLink>
                general@sda.org.au
              </StyledLink>
              <Icon icon={faEnvelope} />
            </ContainerContact>
            <StyledLink>
              (03) 8611 7000
            </StyledLink>
            <Icon icon={faPhone} />
            {/* </ContainerContact> */}
            {navitems.map((navItem, index) => (
              <LinkContainerExternal
                href={navItem.url}
                rel="noopener"
                target="_blank"
                key={`footer-link-1-${index}`}>
                <StyledLink>
                  {navItem.title}
                </StyledLink>
                <Icon icon={faLongArrowRight} />
              </LinkContainerExternal>
            ))}
          </ContainerContactFull>
          <ContainerSocial>
            <Styleda
              href={'https://www.facebook.com/SDAunion/'}
              rel="noopener"
              target="_blank">
              <StyledIcon icon={faFacebookF} />
            </Styleda>
            <Styleda
              href={'https://twitter.com/sdaunion'}
              rel="noopener"
              target="_blank">
              <StyledIcon icon={faTwitter} />
            </Styleda>
            <Styleda
              href={'https://au.linkedin.com/company/shop-distributive-and-allied-employees-association'}
              rel="noopener"
              target="_blank">
              <StyledIcon icon={faLinkedinIn} />
            </Styleda>
          </ContainerSocial>
        </StyledTopMain>
        <ContainerContactLess>
          <ContainerContact
            style={{ borderTopWidth: '0' }}
            href="mailto:general@sda.org.au">
            <StyledLink>
              general@sda.org.au
            </StyledLink>
            <Icon icon={faEnvelope} />
          </ContainerContact>

          <ContainerContact href="tel:0386117000">
            <StyledLink>
              (03) 8611 7000
            </StyledLink>
            <Icon icon={faPhone} />
          </ContainerContact>

          {navitems.map((navItem, index) => (

            <LinkContainerExternal
              href={navItem.url}
              rel="noopener"
              target="_blank"
              key={`footer-link-2-${index}`}>
              <StyledLink>
                {navItem.title}
              </StyledLink>
              <Icon icon={faLongArrowRight} />
            </LinkContainerExternal>
          ))}
        </ContainerContactLess>
      </StyledTop>
      <StyledBottom>
        <StyledBottomMain>
          <StyledText>
            {colophon}
          </StyledText>
          <StyledTextA
            href={backlink?.url}
            rel="noopener"
            target="_blank">
            <StyledTextAu>
              {backlink?.text[0] + ' '}
              <HoverLink>
                {backlink?.text[1]}
              </HoverLink>
            </StyledTextAu>
          </StyledTextA>
        </StyledBottomMain>
      </StyledBottom>
    </div >
  )
}

export default Footer
